<template>
  <header>
    <nav class="navbar navbar-expand-md navbar-dark fixed-top custom-bg-dark">
      <router-link to="/" class="navbar-brand">
        <img style="max-height:30px;" src="@/assets/logo.png" /> {{title}}
      </router-link>
      <div class="collapse navbar-collapse" id="navbarCollapse">
        <ul class="navbar-nav ml-auto">
          <li class="nav-item">
            <router-link :to="{name: 'Home'}" class="nav-link" exact>Home</router-link>
          </li>
          <li v-if="isLoggedIn" class="nav-item">
            <router-link :to="{name: 'Applications'}" class="nav-link" exact>Applications</router-link>
          </li>
          <li v-if="isLoggedIn" class="nav-item">
            <router-link :to="{name: 'Quotes', params: {applicationId: 0}}" class="nav-link" exact>Quote</router-link>
          </li>
          <li v-if="!isLoggedIn" class="nav-item">
            <router-link :to="{name: 'Login'}" class="nav-link" exact>Login</router-link>
          </li>
          <li v-if="isAdmin" class="nav-item dropdown">
            <a
              class="nav-link dropdown-toggle"
              href="#"
              id="navbarDropdownMenuLink"
              role="button"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >System Admin</a>
            <div class="dropdown-menu dropdown-menu-right" aria-labelledby="navbarDropdownMenuLink">
              <router-link :to="{name: 'Fees'}" class="dropdown-item" exact>Fees</router-link>
              <router-link :to="{name: 'Margins'}" class="dropdown-item" exact>Margins</router-link>
              <router-link :to="{name: 'PickLists'}" class="dropdown-item" exact>Pick Lists</router-link>
              <router-link :to="{name: 'PromotionCodes'}" class="dropdown-item" exact>Promotion Codes</router-link>
              <router-link :to="{name: 'SystemProperties'}" class="dropdown-item" exact>System Properties</router-link>
              <router-link :to="{name: 'Users'}" class="dropdown-item" exact>Users</router-link>
              <router-link :to="{name: 'LeadDistribution'}" class="dropdown-item" exact>Lead Distribution</router-link>
            </div>
          </li>
          <li v-if="isLoggedIn" class="nav-item dropdown">
            <a
              class="nav-link dropdown-toggle"
              href="#"
              id="navbarDropdownMenuLink"
              role="button"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >{{firstName}}</a>
            <div class="dropdown-menu dropdown-menu-right" aria-labelledby="navbarDropdownMenuLink">
              <router-link :to="{name: 'Logout'}" class="dropdown-item" exact>Logout</router-link>
              <router-link :to="{name: 'Profile'}" class="dropdown-item" exact>Profile</router-link>
            </div>
          </li>
        </ul>
      </div>
    </nav>
  </header>
</template>

<script>
import Store from '@/store'
import RTE from '@/rte'

export default {
  name: 'navbar',
  computed: {
    isLoggedIn: function() {
      return Store.state.isLoggedIn;
    },
    isAdmin: function() {
      return (Store.state.userInfo != null) ? Store.state.userInfo.admin : false;
    },
    firstName: function() {
      return (Store.state.userInfo != null) ? Store.state.userInfo.firstName : "";
    },
  },
  data: function () {
    return {
      title: RTE.getAppTitle(),
    }
  },

}
</script>

