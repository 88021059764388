<template>
  <div id="custom-home">
    <Welcome />
  </div>
</template>

<script>
  // @ is an alias to /src
  import Welcome from '@/components/Welcome.vue'

  export default {
    name: 'welcome',
    components: {
      Welcome
    },
    metaInfo() {
      return {
        title: this.title,
        meta: [
          { name: 'robots', content: 'noindex, nofollow' }
        ]
      }
    }
  }
</script>
