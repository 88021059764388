var RTE = {
	getApiURL: function() {
		var rte = this.getRTE();

		return rte.apiURL;
	},
	
	getAppTitle: function() {
		var rte = this.getRTE();

		return rte.appTitle;
	},

	getBaseURL: function() {
		var rte = this.getRTE();

		return rte.baseURL;
	},
	
	getRTE: function() {
		var rte = {};
		var name = self.location.hostname;

		if (name === "localhost" || name === "ubuntu-18") {
			rte = {
				apiURL: "http://localhost:8080/RateRabbit/webapi",
				appTitle: "RateRabbit Administration (LOCAL DEVELOPMENT)",
				baseURL: "/",
			}
		} else if (name === "development.raterabbit.com") {
			rte = {
				apiURL: "https://development.raterabbit.com/RateRabbit/webapi",
				appTitle: "RateRabbit Administration (DEVELOPMENT)",
				baseURL: "/admin/",
			}
		} else if (name === "stage.raterabbit.com" || name === "staging.raterabbit.com") {
			rte = {
				apiURL: "https://stage.raterabbit.com/RateRabbit/webapi",
				appTitle: "RateRabbit Administration (STAGING)",
				baseURL: "/admin/",
			}
		} else if (name === "www.raterabbit.com" || name === "raterabbit.com") {
			rte = {
				apiURL: "https://www.raterabbit.com/RateRabbit/webapi",
				appTitle: "RateRabbit Administration",
				baseURL: "/admin/",
			}
		}

		return rte;
	},
}

export default RTE;
