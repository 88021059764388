import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '@/views/Home.vue'
import Store from '@/store'

Vue.use(VueRouter)

const routes = [{
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/about',
    name: 'About',
    component: () => import('../views/About.vue')
  },
  {
    path: '/applications',
    name: 'Applications',
    component: () => import('../views/Applications.vue'),
    beforeEnter: (to, from, next) => { (Store.state.isLoggedIn) ? next() : next('/login'); }
  },
  {
    path: '/applications-edit/:applicationId',
    name: 'ApplicationsEdit',
    component: () => import('../views/ApplicationsEdit.vue'),
    beforeEnter: (to, from, next) => { (Store.state.isLoggedIn) ? next() : next('/login'); },
  },
  {
    path: '/fees',
    name: 'Fees',
    component: () => import('../views/Fees.vue'),
    beforeEnter: (to, from, next) => { (Store.state.isLoggedIn) ? next() : next('/login'); }
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('../views/Login.vue'),
    beforeEnter: (to, from, next) => { (Store.state.isLoggedIn) ? next('/') : next(); }
  },
  {
    path: '/reset-password/:token',
    name: 'ResetPassword',
    component: () => import('../views/ResetPassword.vue'),
    beforeEnter: (to, from, next) => { (Store.state.isLoggedIn) ? next('/') : next(); }
  },
  {
    path: '/logout',
    name: 'Logout',
    component: () => import('../views/Logout.vue'),
  },
  {
    path: '/margins',
    name: 'Margins',
    component: () => import('../views/Margins.vue'),
    beforeEnter: (to, from, next) => { (Store.state.isLoggedIn) ? next() : next('/login'); }
  },
  {
    path: '/pick-lists',
    name: 'PickLists',
    component: () => import('../views/PickLists.vue'),
    beforeEnter: (to, from, next) => { (Store.state.isLoggedIn) ? next() : next('/login'); }
  },
  {
    path: '/profile',
    name: 'Profile',
    component: () => import('../views/Profile.vue'),
    beforeEnter: (to, from, next) => { (Store.state.isLoggedIn) ? next() : next('/login'); }
  },
  {
    path: '/promotion-codes',
    name: 'PromotionCodes',
    component: () => import('../views/PromotionCodes.vue'),
    beforeEnter: (to, from, next) => { (Store.state.isLoggedIn) ? next() : next('/login'); }
  },
  {
    path: '/quotes/:applicationId',
    name: 'Quotes',
    component: () => import('../views/Quotes.vue'),
    beforeEnter: (to, from, next) => { (Store.state.isLoggedIn) ? next() : next('/login'); },
  },
  {
    path: '/system-properties',
    name: 'SystemProperties',
    component: () => import('../views/SystemProperties.vue'),
    beforeEnter: (to, from, next) => { (Store.state.isLoggedIn) ? next() : next('/login'); }
  },
  {
    path: '/users',
    name: 'Users',
    component: () => import('../views/Users.vue'),
    beforeEnter: (to, from, next) => { (Store.state.isLoggedIn) ? next() : next('/login'); }
  },
  {
    path: '/lead-distribution',
    name: 'LeadDistribution',
    component: () => import('../views/LeadDistribution.vue'),
    beforeEnter: (to, from, next) => { (Store.state.isLoggedIn) ? next() : next('/login'); }
  },
  {
    path: '*',
    redirect: '/'
  }

]

const router = new VueRouter({
  routes: routes,
  linkActiveClass: 'active',
  mode: 'history',
  base: "/admin/"
})

export default router
