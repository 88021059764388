<template>
  <div id="app">
    <Navbar />
    <div id="app-container">
      <router-view />
    </div>
    <Footer />
  </div>
</template>

<script>
  import Footer from '@/components/Footer.vue'
  import Navbar from '@/components/Navbar.vue'
  import Store from '@/store'

  export default {
    name: 'app',
    metaInfo() {
      return {
        title: this.title,
        meta: [
          { name: 'robots', content: 'noindex, nofollow' }
        ]
      }
    },
    beforeEnter: Store.dispatch('initialize'),
    components: {
      Footer,
      Navbar
    }
  }
</script>
